#page {
    text-align: center;
}

#dropContainer{
    width: 500px;
    height: 250px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    display: inline-block;
}

#dropHere {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#fileNameContainer{
    position: relative; 
    display: inline-block; 
    width: 0;
    height: 0; 
    vertical-align: top
}

#fileNames{
    position: absolute;
    padding-left: 20px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

#result {
    padding-top: 20px;
    display: grid;
    text-align: center;
    justify-content: center;
}

.targetprice{
    size: 5;
}

.employeeData {
  width: 95%;
  padding: 10px 10px 10px 10px;
  float: left;
  margin-top: 15px;
  border-bottom: solid gray 1px;
}

.employeeData .employeeName {
  float: left;
  font-size: 20px;
  font-weight: bold;
}

.employee {
  display: inline-block;
}
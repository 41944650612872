.data-query select {
    height: 35px;
    width: 250px;
    margin: 10px;
    border-color: #97c1A9;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
}

.data-query .dates span {
    height: 100%;
    font-weight: bold;
    font-size: 35px;
}

.data-query .dates select {
    width: 125px !important;
    margin-right: 5px;
    margin-left: 5px;
}

.search {
    vertical-align: top;
    margin-top: 10px;
    border-color: #97c1A9;  
    border-radius: 5px;
    height: 35px;
    width: 75px;
    background-color: white;
}

.data-query .search:hover {
    background-color: #CCe2CB;
}

.customer-data {
    margin-top: 15px;
}
.views {
    float:left;
    width: 100%;
    justify-content: center;
}
.montly-view{
    min-width: 85%;
    min-height: 100px;
    float: left;
    margin-top: 20px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}

.taskTable { 
    margin: 5px;
    font-size: smaller;
    border-bottom: solid 1px lightslategray;
}

.montly-view .month {
    font-weight: bold;
    padding: 10px 10px 10px 10px;
    text-align: left;
    border-bottom: solid 1px lightslategray;
}

.montly-view .custom-values{
    padding: 10px 10px 10px 10px;
    text-align: center;
}

.montly-view .customer-name{
    font-weight: bold;
}


.montly-view table {
    border-collapse: collapse;
}

.cost { background-color: #EA4B4B;}

.montly-view tbody tr:nth-child(even) {background: #CCe2CB}
.montly-view tbody tr:nth-child(odd) {background: #FFF}
.montly-view tfoot tr {background-color:#BFFCC6}

.loss {background-color: #EA4B4B !important}
.gain {background-color: #BFFCC6 !important}

.montly-view thead {
    font-weight: bold;
    background-color: #97c1A9;
}

.montly-view td {
    border-collapse: collapse;
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
}

.montly-view th {
    padding-left: 10px;
    padding-right: 10px;
}

.value-header {
    font-weight: bold;
    padding: 10px 10px 10px 10px;
}

.montly-view .product-table {
    margin-bottom: 20px;
    border-bottom: 1px solid #97c1A9;
}

.margin-around {
    margin: 10px 10px 10px 10px;
}
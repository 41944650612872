.customer-data-row td {
    height: 25px;
    width: 100px;
    border-collapse: collapse;
}

.customer-pricing table {
    border-collapse: collapse;
}
.customer-pricing .tasks {
    font-weight: bold;
    background-color: #97c1A9;
}

.customer-pricing tbody tr:nth-child(even) {background: #CCe2CB}
.customer-pricing tbody tr:nth-child(odd) {background: #FFF}

.customer-name {
    font-weight: bold;
}

.pricing{
    margin-top: 50px;
}
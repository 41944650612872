.App {
  text-align: center;
  display: block;
  height: 100%;
}

.App .nav {
  height: 50px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid gray;
  margin-bottom: 20px;
}

.App .nav .currentPage{
  border-bottom: solid 1px rgba(151, 193, 169, 0.5);
}

.nav a {
  float: left;
  color: #000000;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 17px;
}

/* Right-aligned section inside the top navigation */
.nav-right {
  float: right;
}


.App .Input {
  width: auto;
  text-align: center;
}

.App .LoginPage {
  padding-top: 50px;
  align-items: center;
}

.App .LoginPage .Login {
  min-width: 450px;
  min-height: 150px;
  border-style: solid;
  border-color: #97c1A9;
  border-width: 1px;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  display: inline-block;
}

.App .LoginPage .Login form{
  padding-top: 25px;
}

.App .LoginPage .Login .row{
  padding-bottom: 15px;
}

.App .LoginPage .Login form input{
  border-radius: 5px;
  line-height: 20px;
  border-color: #CCe2CB;
}

.App .LoginPage .Login form input:disabled{
  border-radius: 5px;
  line-height: 20px;
  border-color: #FF0000;
}

.user-input {
    height: 15px;
    width: 75px;
}

.profit {
  background-color: #4dab82;
}

.checkbox {
  padding-top: 10px;
  padding-bottom: 10px;
}
.group-header {
  font-size: large;
}

.checkbox input {
  position: absolute;
  margin-top: 0px;
  width: 20px;
  height: 20px;
}